import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastService } from '../ui/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService ,private toastService: ToastService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url === 'https://sistemageodigitalazb.com/APPTURISTA/NewServAppCh.php'){
      return next.handle(req);
    }
    if(req.url === 'https://us-central1-chiapas-pb.cloudfunctions.net/MinTour'){
      return next.handle(req);
    }

    const token: string = localStorage.getItem('X-AUTH-TURISMO');
    let request = req;
    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`
        }
      });
    }
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.toastService.presentToast('tu sesion ha expidado');
          this.authService.logout();
        }
        return throwError( err );
      })
    );
  }
}
