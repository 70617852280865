// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'floods-b81b8',
    appId: '1:895017913996:web:5ce677e30d4dfb8f02b5a9',
    databaseURL: 'https://floods-b81b8.firebaseio.com',
    storageBucket: 'floods-b81b8.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyDxL1Inw7zdGS0vWcPriVpPSCVdIzkCbtM',
    authDomain: 'floods-b81b8.firebaseapp.com',
    messagingSenderId: '895017913996',
    measurementId: 'G-DXQ700BK57',
  },
  production: false,
  api: 'https://api.plataformasecturchiapas.mx/api',
  // api: 'https://sharp-saha.108-175-11-59.plesk.page/app/api',
  // api: 'https://apiturismo.test/api',
  // socket:'https://socket.plataformasecturchiapas.mx',
  socket:'http://localhost:3000',
  url:'http://localhost:8100',
  quejas:'https://plataformasecturchiapas.mx/turismo/registrar_es.php',
  encuesta:'https://plataformasecturchiapas.mx/encuestas/t/4',
  seguimiento:'https://turista.plataformasecturchiapas.mx',
  resource: 'https://plataformasecturchiapas.mx/turismo/archivos/',
  apiKeyMaps: 'AIzaSyALlnsTAtCio0TTr0nJEPgrsnxYH8YoZNE',
  apiMaps: 'https://www.google.com/maps/embed/v1',
  version:'2022.7.01'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
