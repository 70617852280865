import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './services/auth.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'reserva',
    pathMatch: 'full'
  },
  {
    path: 'populares',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'establecimientos',
    children: [
      {
        path:'',
        loadChildren: () => import('./pages/establishment/establishment.module').then( m => m.EstablishmentPageModule)
      },
      {
        path:':id',
        loadChildren: () => import('./pages/detail/detail.module').then( m => m.DetailPageModule)
      }
    ]
  },
  {
    path: 'rutas',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/rutas-menu/rutas-menu.module').then( m => m.RutasMenuPageModule)
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/rutas/rutas.module').then( m => m.RutasPageModule)
      }
    ]
  },
  {
    path: 'reservaciones',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/reservaciones/reservaciones.module').then( m => m.ReservacionesPageModule)
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/reservaciones/detail/detail.module').then( m => m.DetailPageModule)
      }
    ],
    canActivate:[AuthGuard],
  },
  {
    path: 'quejas',
    loadChildren: () => import('./pages/queja/queja.module').then( m => m.QuejaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'comprar/:id',
    loadChildren: () => import('./pages/comprar/comprar.module').then( m => m.ComprarPageModule),
    //  canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/autenticacion/autenticacion.module').then( m => m.AutenticacionPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'favoritos',
    loadChildren: () => import('./pages/favoritos/favoritos.module').then( m => m.FavoritosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'itinerario',
    loadChildren: () => import('./pages/itinerario/itinerario.module').then( m => m.ItinerarioPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'experiencias',
    loadChildren: () => import('./pages/experiencias/experiencias.module').then( m => m.ExperienciasPageModule)
  },
  {
    path: 'encuestas',
    loadChildren: () => import('./pages/encuestas/encuestas.module').then( m => m.EncuestasPageModule)
  },
  {
    path: 'promociones',
    loadChildren: () => import('./pages/promociones/promociones.module').then( m => m.PromocionesPageModule)
  },
  {
    path: 'reserva',
    loadChildren: () => import('./pages/reserva/reserva.module').then( m => m.ReservaPageModule)
  },
  {
    path: 'recuperar',
    loadChildren: () => import('./pages/recuperar/recuperar.module').then( m => m.RecuperarPageModule)
  },
  {
    path: 'centros',
    loadChildren: () => import('./pages/centros/centros.module').then( m => m.CentrosPageModule)
  },
  {
    path: '**',
    redirectTo:'reserva',
    pathMatch:'full'
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
