import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from './services/auth.service';
import { Component } from '@angular/core';
import { EstablecimientoService } from './services/establecimiento.service';
import { FormControl } from '@angular/forms';
import { InstaladorService } from './services/hardware/instalador.service';
import { ToastService } from './services/ui/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public user: any;
  idioma: FormControl;
  langs = ['es','en'];
  public appPages = [
    // { title: 'inicio', url: '/inicio', icon: 'home', protected: false },
    { title: 'promociones', url: '/promociones', icon: 'ticket', protected: false },
    { title: 'programa tu viaje', url: '/itinerario', icon: 'time', protected: true },
    { title: 'establecimientos', url: '/establecimientos', icon: 'business', protected: false },
    { title: 'rutas', url: '/rutas', icon: 'navigate', protected: false },
    { title: 'reseña tu viaje', url: '/experiencias', icon: 'id-card', protected: false },
    { title: 'reservaciones', url: '/reservaciones', icon: 'card', protected: true },
    { title: 'quejas', url: '/quejas', icon: 'megaphone', protected: true },
    { title: 'favoritos', url: '/favoritos', icon: 'heart', protected: true },
    { title: 'encuestas', url: '/encuestas', icon: 'help-circle', protected: false},
  ];
  public authRoutes = [];
  public version: string;
  isAuth = false;
  constructor(
      public authService: AuthService,
      private afAuth: AngularFireAuth,
      private translate: TranslateService
    ) {
    this.idioma = new FormControl('es');
    this.version = environment.version;
    this.authService.$session.subscribe( session => {
      if (session) {
        this.authService.$profile.subscribe( profile => this.user = profile, err => console.error(err));
        this.isAuth = this.authService.isAuthenticated();
      }
    });
    this.translate.addLangs(this.langs);
    this.translate.use(this.idioma.value);
    this.translate.stream('menu').subscribe(menu =>{
      this.appPages = menu;
    }, err => console.error(err));
    this.translate.stream('authMenu').subscribe(authMenu =>{
      this.authRoutes = authMenu;
    }, err => console.error(err));
  }
  logout(){
    this.afAuth.signOut();
    this.authService.logout();
  }
  setIdioma(){
    this.translate.use(this.idioma.value);
  }
}
