import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhonePipe } from './phone.pipe';
import { FechaPipe } from './fecha.pipe';



@NgModule({
  declarations: [PhonePipe, FechaPipe],
  imports: [
    CommonModule
  ],
  exports:[
    PhonePipe,
    FechaPipe
  ]
})
export class PipesModule { }
