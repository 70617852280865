import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { IonicSafeString, Mode, AnimationBuilder, TextFieldTypes } from '@ionic/core';


interface AlertButton {
  text: string;
  role?: 'cancel' | 'destructive' | string;
  cssClass?: string | string[];
  handler?: (value: any) => boolean | void | {[key: string]: any};
}
interface AlertInput {
  type?: TextFieldTypes | 'checkbox' | 'radio' | 'textarea';
  name?: string;
  placeholder?: string;
  value?: any;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  handler?: (input: AlertInput) => void;
  min?: string | number;
  max?: string | number;
  cssClass?: string | string[];
  attributes?: { [key: string]: any };
  tabindex?: number;
}
interface AlertOptions {
  header?: string;
  subHeader?: string;
  message?: string | IonicSafeString;
  cssClass?: string | string[];
  inputs?: AlertInput[];
  buttons?: (AlertButton | string)[];
  backdropDismiss?: boolean;
  translucent?: boolean;
  animated?: boolean;
  htmlAttributes?: { [key: string]: any };

  mode?: Mode;
  keyboardClose?: boolean;
  id?: string;

  enterAnimation?: AnimationBuilder;
  leaveAnimation?: AnimationBuilder;
}
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alert: any;
  constructor(private alertController: AlertController) { }
  async presentAlert(header: string, subHeader: string, message: string, buttons: AlertButton[] = [{text:'Aceptar'}]) {
    this.alert = await this.alertController.create({
      // cssClass: '',
      header,
      subHeader,
      message,
      buttons
    });
    await this.alert.present();
    return this.alert;
  }
}
