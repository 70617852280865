import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { AlertService } from './ui/alert.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private alertService: AlertService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      const isAuth = this.authService.isAuthenticated();
      if (isAuth) {
        return true;
      }
      this.authService.logout();
      this.alertService.presentAlert('Aviso','','Inicia sesión para acceder a reservaciones y mas servicios');
      this.router.navigate(['login'],{
        queryParams:{redirectTo:state.url}
      });
      return false;
  }
}
