import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryRoutingModule } from './gallery-routing.module';
import { IonicModule } from '@ionic/angular';
import { GalleryComponent } from './gallery.component';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [ GalleryComponent ],
  imports: [
    CommonModule,
    IonicModule,
    GalleryRoutingModule,
    SwiperModule
  ],
  exports: [
    GalleryComponent
  ]
})
export class GalleryModule { }
