import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { GestureController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  @Input() data: any[];
  @Input() type: string;
  @ViewChild('gallery') img: ElementRef;
  url: string;
  position: any;
  constructor(private modalController: ModalController, private gestureCtrl: GestureController) {
    this.url = '';
  }
  ngOnInit() {
    console.log(this.data);
    this.url = this.data[0]?.url;
  }
  setImagen(url){
    this.url = url;
    if (this.img) {
      const gesture = this.gestureCtrl.create({
        el: this.img.nativeElement,
        onMove: detail =>  this.onMove(detail) ,
        gestureName: 'swipe'
      });
      gesture.enable();
    }
  }
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }
  private onMove(detail) {
    this.position = detail;
    console.log(detail);
  }
}
