import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import decode from 'jwt-decode';
interface Login{
  correo?: string;
  password?: string;
  token?: string;
}

interface Registro {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  id_pais: string;
  nombre: string;
  apellidos: string;
  correo: string;
  password: string;
  telefono: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public profileSource = new BehaviorSubject<any>(null);
  public authSource = new BehaviorSubject<string>(null);
  public $session = this.authSource.asObservable();
  public $profile = this.profileSource.asObservable();
  private tokenName = 'X-AUTH-TURISMO';
  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) {
    this.loadAuthData();
  }
  login(payload: Login){
    return this.http.post(`${environment.api}/autenticacion/login`,payload);
  }
  saveSession(token: string){
    if (token && token.length > 10) {
      localStorage.setItem(this.tokenName, token);
    }else{
      localStorage.removeItem(this.tokenName);
    }
    this.authSource.next(token);
  }
  setProfile(profile: any){
    if(profile && profile.id_turista){
      const parsed = JSON.stringify(profile);
      localStorage.setItem('X-PROFILE-TURISMO', parsed);
    }else{
      localStorage.removeItem('X-PROFILE-TURISMO');
    }
    this.profileSource.next(profile);
  }
  logout(){
    const token = localStorage.getItem(this.tokenName);
    this.saveSession(null);
    this.setProfile(null);
    localStorage.removeItem(this.tokenName);
    localStorage.removeItem('X-PROFILE-TURISMO');
    if (token) {
      this.http.post(`${environment.api}/autenticacion/logout`, {token}).subscribe(mensaje => {
        // this.authSource.next(null);
        location.reload();
      }, err => {
        console.error(err);
        location.reload();
      });
    }
  }
  isAuthenticated(): boolean {
    const token = localStorage.getItem(this.tokenName);
    // Check whether the token is expired and return
    // true or false
    if (!token) {
      return false;
    }
    return !this.jwtHelper.isTokenExpired(token);
  }
  registro(payload: Registro){
    return this.http.post(`${environment.api}/autenticacion/registro`, payload);
  }
  loadAuthData(){
    const token = localStorage.getItem(this.tokenName);
    const profile = localStorage.getItem('X-PROFILE-TURISMO');
    if (token) {
      this.saveSession(token);
      if (profile) {
        const decripted = JSON.parse(profile);
        if (decripted.id_turista) {
          this.setProfile(decripted);
        }
      }
    }
  }
  itinerario(idTurista: number){
    return this.http.get(`${environment.api}/turistas/itinerario/${idTurista}`);
  }
  actualizarItinerario(idTurista: number, payload: any){
    return this.http.put(`${environment.api}/turistas/itinerario/${idTurista}`, payload);
  }
  existe(correo: string){
    return this.http.post(`${environment.api}/autenticacion/existe`, {correo});
  }
  update(payload: any, id: number){
    return this.http.put(`${environment.api}/turistas/${id}`,payload);
  }
  recuperar(correo: string){
    return this.http.post(`${environment.api}/autenticacion/recuperar`,{correo});
  }
}
