import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(rawNumber: string, ...args: string[]): string {
    return `(${rawNumber.slice(0,3)}) ${rawNumber.slice(3,6)} ${rawNumber.slice(6)}`;
  }

}
